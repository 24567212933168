body{
  font-family:'Montserrat', 'Helvetica', Arial, sans-serif;
  background:#050422;
  color:#fff;
  --grid-spacing:10px;
  --background:#222;
  --highlight-color:#40c4b5;
}
nav{
  height: 100vh;
  width: 0;
  transition: width .3s, height .3s;
  overflow: hidden;
  overflow-y: scroll;
}
nav ul{
  width: 100%;
  list-style-type: none;
  margin:0;
  padding: 0;
  width: 300px;
}
nav ul li{
  width: calc(300px - 40%);
}
nav ul li a{
  color: #fff;
  font-weight: bold;
  padding: 10%;
  border-bottom:1px solid #14142c;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  font-size: .8em;
}
nav ul li a img{
  max-width: 100%;
}
nav ul li.relatedHeader{
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding: 5%;
  font-size: .9em;
}

nav details.relatedCategory summary{
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: .75em;
  margin-left: 5%;
  margin-top: 2.5%;
  display: inline-block;
  color: #d4e842;
  cursor: pointer;
}
nav details.relatedCategory{
  width: 100%;
}
nav details.relatedCategory ul li{
  width: 70%;
}
nav details.relatedCategory li a{
  font-weight: 400;
  font-size: .9em;
  padding:5% 10%;
}
a{
  text-decoration: none;
}
nav ul li a:hover{
  background: var(--highlight-color);
}
nav.active{
  width: 300px;
}

#video-hero{
  padding: 3vw;
  min-height: 900px;
}
#videoWidget{
  margin: 0px auto;
  display: flex;
  padding:0 0 0 2%;
}
header{
  width: 96%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 2%;
}
.bc-logo{
  display: block;
  padding: 2%;
}
header img.vm-logo{
  width: 180px;
}
header input{
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, .1);
  padding: 1%;
  width: 100%;
  color: #fff;
  margin-bottom: .3rem;
  font-size: .9em;
  background: rgba(255,255,255,.1);
  width:25%;
}
.menuToggle{
  width: calc(20% - 30px);
  align-items: center;
  display: flex;
  transition: background .3s;
}
.menuToggle:hover{
  cursor: pointer;
  background: #14142c;
}
.menuToggle img{
  margin-right: 1rem;
}
#videos{
  width: 66.66%;
  height: calc(100vh + 140px);
  overflow-y: scroll;
  padding-right: 2%;
}
#form{
  width: calc(29.33%);
  padding:2rem;
  background: #14142c;
}
#form form{
  padding-top: 2%;
  margin-top: 10%;
  border-top: 1px solid rgba(255, 255, 255, .3);
}
#form input, #form textarea{
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, .1);
  padding: 2%;
  width: 96%;
  color: #fff;
  margin-bottom: 1rem;
  font-size: .9em;
  background: rgba(255,255,255,.1);
}
#form select{
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, .1);
  padding: 2%;
  width: 100%;
  color: #fff;
  margin-bottom: 1rem;
  font-size: .9em;
  background: #2c2d42;
}
#form input[type="checkbox"]{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, .1);
  padding: 2%;
  color: #fff;
  margin: .5rem 1rem .5rem 0;
  font-size: calc(18px - 4%);
  background: rgba(255,255,255,.1);
  position: relative;
  float: left;
  display: inline-block;
  width: 20px;
  height: 20px;
}

#form input[type="checkbox"]::before{
  content:'\2713';
  transition:.2s transform, .2s opacity;
  transform:rotateZ(45deg);
  opacity: 0;
  position: absolute;
  top: -10%;
  left: 1px;
  font-size: 18px;
}
#form input[type="checkbox"].checked::before{

  opacity: 1;
  transform:rotateZ(0deg);
  color:#fff;
}
.hidden{
  display: none !important;
}
#form span.error{
  display: none;
}
#form span.error.invalid{
  display: block;
}
#form label[for="receive_updates"]{
  font-size: .8em;
}
#form button.cta.submit, #form button.download{
  margin:5% 0;
  transition: background .2s, border .2s;
}
#videoWidget h1{
  font-weight: 800;
}
#videoWidget h4{
  font-weight: 400;
}
#form h3{
  text-align: center;
}
#form img{
  max-height: 150px;
}

section.headerImage{
  display: none;
  background: linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), url('/header-bg.jpg');
  padding: 20% 10%;
  margin-bottom: 5%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
section.headerImage.active{
  display: block;
}
section.videoHero{
  width:100%;
  padding-bottom: 56.25%;
  position:relative;
  display: none;
}
section.videoInfo{
  display: none;
}
section.videoInfo svg{
  margin: 0 1% 0 0;
  width: 25px;
}
section.videoInfo a{
  margin: 1% 0;
  border:none;
}
section.videoHero.active, section.videoInfo.active{
  display: block;
}
section.videoHero .video-js, section.videoHero .vjs-pip-container{
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
}
img.logoOverlay{
  height: 20px;
  position: absolute;
  z-index: 100;
  bottom: 0;
  right: 0;
  margin: 20px;
}
section.videoInfo{
  margin-bottom:20px;
}
section.videoInfo a, button.loadMore{
  color:var(--highlight-color);
  border: 1px solid var(--highlight-color);
  text-decoration: none;
  display: inline-block;
  padding: 10px;
  font-weight: 400;
  background:transparent;
}
section.videoInfo .relatedWrapper{
  border-bottom: 1px solid rgba(255,255,255,.3);
  margin-top: 3%;
  border-top: 1px solid rgba(255,255,255,.3);
  padding-bottom: 2%;
}
section.videoInfo em{
  display: inline-block;
  padding: 1% 2% 1% 0;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  background: #050422;
  transform: translateY(-50%);
}
section.videoInfo .relatedWrapper .thumbsHolder{
  list-style-type: none;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: -2% 0 0 0;
  justify-content: space-between;
}
section.videoInfo .relatedWrapper .thumbsHolder a{
  width: 20%;
  background: rgba(255,255,255,.1);
  border: 0;
  color: #fff;
  transition:transform .3s;
  font-size: .8em;
}
section.videoInfo .relatedWrapper .thumbsHolder a:hover{
  background: rgba(255,255,255,.3);
  transform:translateY(-2%);
}
section.videoInfo .relatedWrapper .thumbsHolder li .imgHolder{
  height: 0;
  width: 100%;
  padding-top: 56.25%;
  display: inline-block;
  margin-bottom: 10px;
}
section.videoInfo .shareWrap{
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
}
section.videoInfo .shareWrap a{
  border:none;
}
section.videoInfo .shareWrap span{
  padding: 10px;
}
button.cta::before, button.download::before{
  content: '';
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: -1;
  margin: -2px; /* !importanté */
  border-radius: inherit; /* !importanté */
  background: linear-gradient(to right, #d4e842, #40c4b5);
}
button.cta, button.download:hover{
  color: #FFF;
  border-radius: 3px;
  background: linear-gradient(to right, #d4e842, #40c4b5);
  border: 2px solid transparent;
  text-transform: uppercase;
  background-clip: padding-box;
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  padding: 2% 6%;
  /* width: 33%; */
  text-align: center;
  display: block;
  /* margin: 0px auto; */
  position: relative;
  letter-spacing: 1px;
  box-shadow: 0 3px 10px rgba(0,0,0,.4);
}

button.download, button.cta:hover{
  color: #FFF;
  border-radius: 3px;
  border: 2px solid #fff;
  background: transparent;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  padding: 2% 6%;
  cursor:pointer;
  /* width: 33%; */
  text-align: center;
  display: block;
  /* margin: 0px auto; */
  position: relative;
  letter-spacing: 1px;
  box-shadow: 0 3px 10px rgba(0,0,0,.4);
}

section.videoInfo a:hover, button.loadMore:hover{
  background-color: var(--highlight-color);
  color:#fff;
  cursor: pointer;
}
button.loadMore{
  display: inline-block;
  margin:20px auto;
}
section.videoInfo h2 small{
  font-weight: 100;
}
section.videoInfo div{
  white-space: pre-line;
}
section.videoInfo small{
  display: block;
  opacity: .5;
  margin-top: 10px;
}
section.gridHolder ul.thumbsHolder{
  margin:0;
  padding:0;
  display:grid;
  grid-template-columns: 1;
  grid-template-rows: auto;
  grid-column-gap: var(--grid-spacing);
  grid-row-gap: var(--grid-spacing);
  clear:both;
}
section.gridHolder ul li{
  list-style-type:none;
  margin: 0 0 1vh 0;
  position:relative;
}
section.gridHolder ul li:hover{
  cursor:pointer;
}
section.gridHolder ul.playlistTabs{
  padding:0;
  margin:0px auto;
}
section.gridHolder ul.playlistTabs li{
  display:inline-block;
  float:left;
  border:1px solid transparent;
  margin-right:1vw;
  padding:1vw;
  color:#737373;
}
section.gridHolder ul.playlistTabs li:hover{
  color:#fff;
  background: #333333;
}
section.gridHolder ul.playlistTabs li.active{
  border:1px solid #d62384;
  color:#fff;
}
section.gridHolder ul li.vidThumb{
  transition: .3s all;
  border-radius: 2px;
  box-shadow: 0 3px 10px rgba(0,0,0,.4);
  overflow:hidden;
  display: flex;
}
section.gridHolder ul li.vidThumb .infoHolder, section.gridHolder ul li.vidThumb .dateWrap{
  width: calc(30% - 2vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1vh;
}
section.gridHolder ul li.vidThumb .dateWrap{
  text-align: center;
  font-size: .9rem;
  padding: 2%;
  font-weight: 400;
}
section.gridHolder ul li.vidThumb .dateWrap .month{
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 400;
}
section.gridHolder ul li.vidThumb .dateWrap .day{
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: 400;
}
section.gridHolder ul li.vidThumb .infoHolder strong{
  display: block;
  margin:1vh;
}
section.gridHolder ul li.vidThumb p{
  display:inline-block;
  margin:1vh;
  font-weight:400;
}
section.gridHolder ul li.vidThumb small{
  margin: 1vh;
  display: inline-block;
  /* opacity: .5; */
  color:#fff;
  width: fit-content;
  background:rgba(186, 206, 17, .6);
  padding: 2%;
}
section.gridHolder ul li.vidThumb .imgHolder{
  width: 70%;
  padding-top: 39.375%;
  /* height:150px; */
  /* filter:grayscale(1); */
  /* transition:filter .3s; */
  position:relative;
}
section.gridHolder div.thumbsWrapper a{
  /* height: 1100px;
  overflow-y: scroll; */
  color:inherit;
  text-decoration: none;
}
/* section.gridHolder ul li.vidThumb .imgHolder:before{
  content:url('./img/play_logo_white.svg');
  position:absolute;
  left: calc(50% - 50px);
  height: 100px;
  top: calc(50% - 50px);
  width: 100px;
  transform: translateY(-150%);
  transition: transform .3s;
}
section.gridHolder ul li.vidThumb .imgHolder:after{
  content:url('./img/bottom.svg');
  position:absolute;
  left:0px;
  width:100%;
  transform:translateX(100%);
  transition:transform .3s;

}
section.gridHolder ul li.vidThumb:hover .imgHolder:before, section.gridHolder ul li.vidThumb:hover .imgHolder:after{
  transform:translateY(0);
} */
section.gridHolder ul li.vidThumb .highlight{
  position:absolute;
  top:0px;
  left:0px;
  width:100%;
  height:100%;
  z-index:20;
  box-shadow:inset 0 0 3px rgba(255, 255, 255, .3);
}
section.gridHolder ul li.vidThumb:hover{
  background:#14142c;
  /* color:#ea018c; */
  box-shadow: 0 8px 20px rgba(0,0,0,.6);
  transform:translateY(-.5%);
}
/*section.gridHolder ul li.vidThumb:hover .highlight{
  box-shadow:inset 0 2px 3px rgba(255, 255, 255, .3);
}*/
section.gridHolder ul li.vidThumb:hover .imgHolder, section.gridHolder ul li.vidThumb.active .imgHolder{
  filter:grayscale(0);
}
section.gridHolder input[type="text"]{
  display: inline-block;
  padding: 1vw;
  margin-bottom: 1vw;
  width: calc(50% - 1vw);
  border-radius: 2px;
  margin-right:1vw;
  font-size: 1.3rem;
  border:1px solid transparent;
  color:#333;
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
}
section.gridHolder i.clearSearch{
  color: #333;
  z-index: 20;
  transform: translateX(-300%);
  display: none;
}
section.gridHolder i.clearSearch.active{
  display: inline-block;
  cursor: pointer;
}
section.gridHolder div.filters{
  clear:both;
  float: none;
  position: relative;
  z-index: 200;
  height: 80px;
  margin-bottom: 10px;
}
section.gridHolder div.filters details{
  width: 32%;
  display: inline-block;
  border: 1px solid #737373;
  vertical-align: text-top;
  margin-right: 1%;
  margin-bottom: 1%;
  background: var(--background);
}
section.gridHolder div.activeFilters{
  display: inline-block;
  width: calc(50% - 5vw);
  padding: .3vw;
  vertical-align: top;
}
section.gridHolder div.activeFilters small{
  display: inline-block;
  color:#737373;
  border: 1px solid #737373;
  padding: .5vw;
  border-radius: 8px;
  margin: 0 4px 4px 0;
  font-size: 12px;
}
section.gridHolder div.activeFilters small:hover{
  cursor: pointer;
  color:#d62384;
  border: 1px solid #d62384;
}
section.gridHolder div.activeFilters small label{
  cursor: pointer;
  font-weight: 300;
  margin-bottom:0;
}
section.gridHolder div.activeFilters small input[type="checkbox"]{
  display: none;
}
section.gridHolder div.filters details summary{
  padding: 20px;
  cursor:pointer;
}
section.gridHolder div.filters details summary:focus, section.gridHolder input[type="text"]:focus{
  outline:1px solid #d62384;
}
section.gridHolder input[type="text"]:focus{
  border:1px solid #d62384;
}
section.gridHolder div.filters details summary em{
  display: none;
}
section.gridHolder div.filters details summary em.active{
  display: inline-block;
  color:#737373;
  margin-left: 1%;
}
section.gridHolder div.filters ul{
  margin: 0;
  padding: 1vw;
  max-height: 300px;
  overflow: scroll;
}
section.gridHolder div.filters details input[type="checkbox"]{
  position: relative;
  margin-right: 5px;
}
section.gridHolder div.filters ul li label{
  display: inline-block;
  width: calc(100% - .6vw);
  padding:.3vw;
  font-weight: 400;
  font-size:12px;
}
section.gridHolder div.filters ul li label:hover{
  cursor: pointer;
  background: #737373;
}
section.gridHolder div.filters details li.active{
  background: #737373;
}
section.gridHolder div.filters ul li{
  display: inline-block;
  width: 50%;
  vertical-align: top;
  font-weight: 300;
}
.formWrap h3{
  text-align: center;
}
.formWrap .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2%;
}
.formWrap .item img{
  max-height: 100px;
  display: inline-block;
  margin-right: 5%;
}
.formWrap .item strong{
  display: block;
}
/* SOCIAL */
span.social{
  display: inline-block;
  width:35px;
  height: 35px;
}
span.social.fb{
  background-image:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='34.492' height='34.492' viewBox='0 0 34.492 34.492'><path class='a' fill='#ffffff'  d='M991.167,129.947a17.246,17.246,0,1,0,17.246,17.246A17.266,17.266,0,0,0,991.167,129.947Zm4.784,10.23a.26.26,0,0,1-.26.26h-2.075a.922.922,0,0,0-.921.922v2.171h2.95a.261.261,0,0,1,.26.282l-.259,3.061a.26.26,0,0,1-.259.238h-2.691V157.27a.26.26,0,0,1-.26.26h-3.712a.26.26,0,0,1-.26-.26V147.112h-1.856a.26.26,0,0,1-.26-.26v-3.061a.26.26,0,0,1,.26-.26h1.856v-2.95a3.723,3.723,0,0,1,3.724-3.724h3.5a.26.26,0,0,1,.26.26Z'  transform='translate(-973.92 -129.947)'/></svg>");

}
span.social.tw{
  background-image:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='34.492' height='34.492' viewBox='0 0 34.492 34.492'><defs><style>.a{fill:#515151;fill-rule:evenodd;}</style></defs><path class='a' d='M905.63,171.947a17.246,17.246,0,1,0,17.246,17.246A17.266,17.266,0,0,0,905.63,171.947Zm9.987,10.97a.486.486,0,0,1-.1.32,7.978,7.978,0,0,1-1.6,1.673c-.092.068-.245.14-.245.27,0,3.322-.849,6.64-3.27,9.046a12.171,12.171,0,0,1-11.815,2.763,11.3,11.3,0,0,1-2.285-.914c-.241-.131-.479-.268-.711-.422-.06-.047-.1-.092-.053-.123s.089-.027.272,0a8.063,8.063,0,0,0,3.419-.437,5.223,5.223,0,0,0,2.25-1.193c.009-.013.018-.013.026-.027-.15-.035-.3-.035-.449-.061a4.412,4.412,0,0,1-3.3-2.76c-.044-.11,0-.167.115-.145a4.732,4.732,0,0,0,1.568-.049,2.464,2.464,0,0,1-.531-.162,4.25,4.25,0,0,1-2.619-3.388,2.567,2.567,0,0,1-.026-.475c.009-.109.057-.145.154-.083a4.21,4.21,0,0,0,1.6.448c-.18-.14-.347-.294-.509-.448a4.2,4.2,0,0,1-.717-4.965c.08-.119.132-.119.229-.009a12.879,12.879,0,0,0,8.288,4.219c.088.013.1,0,.083-.1a4.448,4.448,0,0,1,.043-1.732,4.226,4.226,0,0,1,.819-1.64,4.39,4.39,0,0,1,1.372-1.12,4.282,4.282,0,0,1,1.7-.48,4.069,4.069,0,0,1,1.792.289,4.27,4.27,0,0,1,1.169.716,1.942,1.942,0,0,1,.211.215.16.16,0,0,0,.166.044,10.359,10.359,0,0,0,2.412-.923.114.114,0,0,1,.107-.009c.035.027.022.071,0,.106a3.734,3.734,0,0,1-.676,1.232c-.193.24-.675.832-1,.887a8.249,8.249,0,0,0,2.03-.58c.082-.037.075-.053.075,0Z' transform='translate(-888.384 -171.947)'/><path class='a' d='M909.029,212.228c-.332,0-.026,0,0,0Z' transform='translate(-895.927 -186.775)'/><path class='a' d='M910.188,212.247c.356,0,0-.034,0,0Z' transform='translate(-896.41 -186.776)'/></svg>");
}
@media (max-width: 1024px){
  section.gridHolder ul.thumbsHolder{
  }
}
@media (max-width: 768px){
  section.gridHolder ul.thumbsHolder li.vidThumb{
    flex-direction: row-reverse;
    align-items: flex-start;
  }
  section.gridHolder ul.thumbsHolder li.vidThumb .infoHolder{
    width: calc(50% - 2vh);
    font-size: .75rem;
  }
  section.gridHolder ul.thumbsHolder li.vidThumb .imgHolder{
    width: 50%;
    background-size: contain !important;
    background-position: center 1vh !important;
    background-repeat: no-repeat;
  }
  nav{
    height:0;
    width: 100vw;
    background: #14142c;
  }
  nav.active{
    height: 50vh;
    width: 100vw;
  }
  header img{
    height: 30px;
  }
  header img.vm-logo{
    max-width: 130px;
  }
  header{
    width: 100%;
    padding: 5% 0;
  }
  .menuToggle{
    font-size: .8rem;
    width: 20%
  }
  .menuToggle img{
    width: 20px;
    height: auto;
    margin-right: .5rem;
  }
  #videoWidget{
    flex-direction: column;
    padding: 0;
  }
  #videos{
    width: 90%;
    height: auto;
    padding: 0 5%;
  }
  #form{
    width: calc(100% - 4rem);
  }
  section.videoInfo .shareWrap{
    float: left;
    width:100%;
    justify-content: left;
  }
  section.videoInfo .shareWrap a{
    margin-top:5px;
  }
  section.videoInfo .shareWrap a, section.videoInfo .shareWrap span{
    padding:5px;
  }
  section.videoInfo svg{
    width:18px;
  }
}

@media (max-width: 600px){
  section.gridHolder ul li.vidThumb .imgHolder{
    height: 90px;
  }
  section.gridHolder ul.thumbsHolder{
  }
  section.gridHolder div.filters{
    height: auto;
  }
  section.gridHolder div.filters details{
    width: 100%;
    margin-bottom: 1%;
    display: block;
    height: auto;
  }
  section.gridHolder input[type="text"]{
    width: 96%;
    margin-right: 0;
  }
  img.logoOverlay{
    display: none;
  }
  nav ul li a img{
    max-height: 20px;
  }
}
@supports (-ms-ime-align:auto) {
    section.gridHolder div.filters{
      height: 300px;
    }
    section.gridHolder ul.thumbsHolder{
      display: flex;
      flex-direction: row;
      align-items: stretch;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    section.gridHolder ul.thumbsHolder li.vidThumb{
      width:100%;
      margin:2% .5%;
    }
    section.gridHolder div.filters ul{
      max-height:182px;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
}
/*  IE 11 */
body.ie section.gridHolder div.filters{
  height: 300px;
}
body.ie section.gridHolder ul.thumbsHolder{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
}
body.ie section.gridHolder ul.thumbsHolder li.vidThumb{
  width:100%;
  margin:2% .5%;
}
body.ie section.gridHolder div.filters ul{
  max-height:182px;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
